import React from "react";
import clsx from "clsx";
import { useSwiper } from "swiper/react";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import styles from "./SwiperNavigationButton.module.scss";

interface SwiperNavigationButtonsProps {
	swiperRef?: any;
	buttonIdentifier: string;
	buttonYPosition?: "middle";
}

interface SwiperNavigationButtonProps extends SwiperNavigationButtonsProps {
	movement: "previous" | "next";
}

const SwiperNavigationButton: React.FC<SwiperNavigationButtonProps> = ({
	movement,
	swiperRef = {},
	buttonIdentifier,
	buttonYPosition,
}) => {
	const swiper = useSwiper();

	// If rendered outside of the swiper element use the onSwiper propr to pass the swiper instance through
	const ref = swiperRef?.enabled ? swiperRef : swiper;

	return (
		<button
			type='button'
			aria-label={`button-${movement}`}
			data-button-ref={`${buttonIdentifier}-button-${movement}`}
			className={clsx([
				styles.carouselArrowButtons,
				styles[`buttonPlacement--${movement}`],
				[buttonYPosition === "middle" ? styles.buttonYPosition : ""],
			])}
			onClick={() => (movement === "next" ? ref.slideNext() : ref.slidePrev())}
		>
			<IconArrow
				direction={movement}
				aria-labelledby={`directional chevron pointing down ${movement}`}
			/>
		</button>
	);
};

const SwiperNavigationButtons: React.FC<SwiperNavigationButtonsProps> = ({
	swiperRef,
	buttonIdentifier,
}) => {
	return (
		<>
			<SwiperNavigationButton
				movement='previous'
				swiperRef={swiperRef}
				buttonIdentifier={buttonIdentifier}
				buttonYPosition='middle'
			/>

			<SwiperNavigationButton
				movement='next'
				swiperRef={swiperRef}
				buttonIdentifier={buttonIdentifier}
				buttonYPosition='middle'
			/>
		</>
	);
};

export default SwiperNavigationButtons;
