"use client";

import clsx from "clsx";
import { FC } from "react";
import { useI18n } from "@/i18n/client";
import { ProductData } from "@/lib/5874/types";
import { useProductContext } from "@/providers/ProductContextProvider";
import FormattedPrice from "@/components/UI/FormattedPrice/FormattedPrice";
import styles from "./Pricing.module.scss";
import { getTranslatedPath } from "@/i18n/urls/urls";
import Link from "next/link";
import useStoreKeys from "@/hooks/useStoreKeys";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";
import useCurrentPath from "@/hooks/useCurrentPath";

type Props = {
	className?: string;
	linkClassName?: string;
	staticPice?: number;
	productQuantity?: number;
};

const Pricing: FC<Props> = ({
	className,
	linkClassName,
	staticPice,
	productQuantity = 1,
}) => {
	const { store, lang } = useStoreKeys();
	const { session } = useSessionContext();
	const currentPath = useCurrentPath();
	const t = useI18n();
	const {
		product: { customFields, pricesExTax, children },
	} = useProductContext();

	if (!session) {
		const href = getTranslatedPath({
			language: lang,
			url: "/login",
			prefix: `/${store}/${lang}`,
		});

		return (
			<Link
				className={clsx([styles.link, linkClassName])}
				href={`${href}?redirect=${currentPath}`}
				prefetch={false}
			>
				{t("account.loginForPricing")}
			</Link>
		);
	}

	const basePrice = pricesExTax?.basePrice?.value * productQuantity;
	const salePrice = (pricesExTax?.salePrice?.value || 0) * productQuantity;

	const percentageDifference =
		basePrice && salePrice > 0
			? ((basePrice - salePrice) / basePrice) * 100
			: 0;

	return (
		<div className={clsx([styles.pricingContainer, className])}>
			{!children ? (
				<>
					<FormattedPrice price={staticPice || salePrice || basePrice} />
					{basePrice && salePrice > 0 && (
						<div className={styles.oldPrice}>
							<FormattedPrice price={basePrice} />
							<span>{` (-${percentageDifference.toFixed(2)}%)`}</span>
						</div>
					)}
				</>
			) : (
				getPriceRange(children)
			)}
		</div>
	);
};

export default Pricing;

const getPriceRange = (children: ProductData[]) => {
	let minPrice = Infinity;
	let maxPrice = -1;

	for (const child of children) {
		const price = child?.price || child?.pricesExTax?.basePrice?.value;

		if (price < minPrice) {
			minPrice = price;
		}

		if (price > maxPrice) {
			maxPrice = price;
		}
	}

	return (
		<div className={styles.range}>
			<FormattedPrice price={minPrice} /> - <FormattedPrice price={maxPrice} />
		</div>
	);
};
