"use client";
import MovoraIcon from "@/app/assets/images/svg/placeholder.svg";
import Link from "next/link";
import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import styles from "./CategoryCarousel.module.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import { Builder } from "@builder.io/react";
// import required modules
import { Navigation, Scrollbar } from "swiper/modules";
import SwiperNavigationButtons from "../SwiperNavigationButton/SwiperNavigationButton";

interface CategoryCarouselProps {
	title: string;
	categories: [
		{
			title: string;
			image: string;
			link: string;
			altText: string;
		},
	];
	source?: string;
}

const CategoryCarousel: React.FC<CategoryCarouselProps> = ({
	title,
	categories,
}) => {
	const carouselId = "category-carousel";

	return (
		<section className={styles.categoryWrapper}>
			<h2 className={styles.title}>{title}</h2>
			<Swiper
				modules={[Scrollbar, Navigation]}
				scrollbar={true}
				navigation={{
					enabled: true,
					nextEl: `[data-button-ref="${carouselId}-button-next"]`,
					prevEl: `[data-button-ref="${carouselId}-button-prev"]`,
				}}
				slidesPerView={2}
				spaceBetween={16}
				breakpoints={{
					1200: {
						slidesPerView: 4.5,
					},
					1000: {
						slidesPerView: 3.5,
					},
					801: {
						slidesPerView: 2.5,
					},
					551: {
						slidesPerView: 2,
					},
				}}
			>
				<div className={styles.swiperArea}>
					{categories?.map((category, index) => (
						<SwiperSlide
							key={`${category.title}_${index}`}
							className={styles.slide}
						>
							<Link href={category?.link === undefined ? "#" : category.link}>
								{category.image ? (
									<img
										src={`${category?.image}?format=webp`}
										alt={category?.altText}
										className={styles.image}
									/>
								) : (
									<div className={styles.image}>
										<MovoraIcon />
									</div>
								)}
								<p className={styles.categoryTitle}>{category?.title}</p>
							</Link>
						</SwiperSlide>
					))}
				</div>

				<SwiperNavigationButtons
					buttonIdentifier={carouselId}
					buttonYPosition={"middle"}
				/>
			</Swiper>
		</section>
	);
};

export default CategoryCarousel;

Builder.registerComponent(CategoryCarousel, {
	name: "Category Carousel",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "string",
			required: true,
			defaultValue: "Featured Categories",
		},
		{
			name: "categories",
			friendlyName: "Categories",
			type: "list",
			defaultValue: [
				{
					title: "THR",
					image: "https://placehold.co/300x300",
					link: "#",
					altText: "This is alt text",
				},
			],
			subFields: [
				{
					name: "title",
					friendlyName: "Title",
					type: "string",
					required: true,
					defaultValue: "THR",
				},
				{
					name: "image",
					friendlyName: "Image",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					defaultValue: "https://placehold.co/300x300",
				},
				{
					name: "link",
					friendlyName: "Link",
					type: "string",
					required: true,
					defaultValue: "#",
				},
				{
					name: "altText",
					friendlyName: "Alt Text",
					type: "string",
					required: true,
					defaultValue: "This is alt text",
				},
			],
		},
	],
});
