"use client";
import MovoraIcon from "@/app/assets/images/svg/placeholder.svg";
import Link from "next/link";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Chevron from "../../app/assets/images/svg/chevron.svg";
import styles from "./ContentCarousel.module.scss";
import clsx from "clsx";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import { Builder } from "@builder.io/react";
// import required modules
import { Navigation, Scrollbar } from "swiper/modules";
import SwiperNavigationButtons from "../SwiperNavigationButton/SwiperNavigationButton";
import { hashString } from "@/lib/5874/util-functions/hashString";

type ContentPanelItem = {
	title: string;
	description: string;
	imageCurveBottom: boolean;
	imageCurveTop: boolean;
	image: string;
	altText: string;
};

type ContentCarouselProps = {
	title: string;
	link: {
		url: string;
		text: string;
		alternativeAria: boolean;
		ariaLabel: string;
	};
	contentPanel: ContentPanelItem[];
};

const ContentCarousel: React.FC<ContentCarouselProps> = ({
	title,
	link,
	contentPanel,
}) => {
	const carouselId = "content-carousel";

	return (
		<section className={`${styles.contentWrapper} ${styles.contentPDPWrapper}`}>
			<div className={styles.header}>
				<h2 className={styles.title}>{title}</h2>
				{link?.text && (
					<Link
						className={styles.link}
						href={link !== undefined ? link.url : "#"}
						aria-label={link?.alternativeAria ? link?.ariaLabel : link?.text}
					>
						{link?.text}&nbsp;
						<Chevron aria-labelledby='directional chevron' />
					</Link>
				)}
			</div>
			<Swiper
				modules={[Scrollbar, Navigation]}
				scrollbar={true}
				navigation={{
					enabled: true,
					nextEl: `[data-button-ref="${carouselId}-button-next"]`,
					prevEl: `[data-button-ref="${carouselId}-button-prev"]`,
				}}
				slidesPerView={3}
				spaceBetween={16}
				breakpoints={{
					1200: {
						slidesPerView: 3,
					},
					1000: {
						slidesPerView: 3,
					},
					801: {
						slidesPerView: 2.5,
					},
					551: {
						slidesPerView: 2,
					},
					0: {
						slidesPerView: 1,
					},
				}}
			>
				{contentPanel?.map((c, i) => (
					<SwiperSlide
						key={`${hashString(title)}-${i}`}
						className={styles.slide}
					>
						{c.image ? (
							<img
								src={`${c?.image}?format=webp`}
								alt={c?.altText}
								className={clsx(styles.image, {
									[styles.imageCurveBottom]: c.imageCurveBottom,
									[styles.imageCurveTop]: c.imageCurveTop,
									[styles.imageCurveTopBottom]:
										c.imageCurveBottom && c.imageCurveTop,
								})}
							/>
						) : (
							<div className={styles.image}>
								<MovoraIcon />
							</div>
						)}
						<div className={styles.contentBlock}>
							<h4 className={styles.contentTitle}>{c?.title}</h4>
							<p className={styles.description}>{c?.description}</p>
						</div>
					</SwiperSlide>
				))}
				<SwiperNavigationButtons buttonIdentifier={carouselId} />
			</Swiper>
		</section>
	);
};

export default ContentCarousel;

Builder.registerComponent(ContentCarousel, {
	name: "Content Carousel",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "string",
			required: true,
			defaultValue: "Content",
			helperText: "~25 characters",
		},
		{
			name: "link",
			type: "object",
			defaultValue: {
				url: "#",
				text: "Learn More",
			},
			subFields: [
				{
					name: "url",
					friendlyName: "URL",
					type: "string",
					defaultValue: "#",
				},
				{
					name: "text",
					friendlyName: "Link Text",
					type: "string",
					defaultValue: "Learn More",
					helperText: "~30 characters",
				},
				{
					name: "alternativeAria",
					friendlyName: "Add Custom Link Aria Label?",
					type: "boolean",
					defaultValue: false,
				},
				{
					name: "ariaLabel",
					friendlyName: "Aria Label",
					type: "long-text",
					showIf: `options.get('alternativeAria')`,
				},
			],
		},
		{
			name: "contentPanel",
			friendlyName: "Content",
			type: "list",
			defaultValue: [
				{
					title: "Title",
					description: "Description of this item",
					imageCurveBottom: true,
					imageCurveTop: false,
					image: "https://placehold.co/400x300.png",
					altText: "description of image",
				},
			],
			subFields: [
				{
					name: "title",
					friendlyName: "Title",
					type: "string",
					required: true,
					defaultValue: "Title",
					helperText: "~25 characters",
				},
				{
					name: "description",
					friendlyName: "Description",
					type: "longText",
					required: true,
					defaultValue: "Description",
					helperText: "~150 characters",
				},
				{
					name: "imageCurveBottom",
					friendlyName: "Add Bottom Image Curve?",
					type: "boolean",
					helperText: "Toggle to add a curve to the bottom of the image",
				},
				{
					name: "imageCurveTop",
					friendlyName: "Add Top Image Curve?",
					type: "boolean",
					helperText: "Toggle to add a curve to the top of the image",
				},
				{
					name: "image",
					friendlyName: "Image",
					type: "file",
					defaultValue: "https://placehold.co/400x300.png",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					helperText: "400×300 px | .jpeg, .jpg, .png | ~200 KB",
				},
				{
					name: "altText",
					friendlyName: "Alt Text",
					type: "string",
					required: true,
					defaultValue: "This is alt text",
				},
			],
		},
	],
});
