const spaces = {
	global: {
		publicKey: "d0fa7415ce1c4512a3033bc8c1a435c5",
	},
	ca: {
		publicKey: "5e644d39f7264dbcab1a56faf6b70245",
	},
	eu: {
		publicKey: "0187df9f62c74f3fbdd96a1d528274ee",
	},
};

export type StoreRegion = keyof typeof spaces;

export const getSpaceDetails = (region: StoreRegion) => {
	const details = spaces[region];

	return details;
};
