import { Builder } from "@builder.io/react";
import Link from "next/link";
import { Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import PublishDate from "../News/PublishDate/PublishDate";
import SwiperNavigationButtons from "../SwiperNavigationButton/SwiperNavigationButton";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import styles from "./EventsCarousel.module.scss";
import { jumbleString } from "@/lib/5874/util-functions/hashString";

interface EventsCarouselProps {
	title: string;
	link: string;
	linkText: string;
	events: [
		{
			title: string;
			price: string;
			startDate: string;
			endDate: string;
			link: string;
			image: string;
			altText: string;
		},
	];
}

const EventsCarousel: React.FC<EventsCarouselProps> = ({
	title,
	link,
	linkText,
	events,
}) => {
	const carouselId = "events-carousel";

	return (
		<section className={styles.eventsCarouselWrapper}>
			<div>
				<div className={styles.titleArea}>
					<h3>{title}</h3>
					<Link href={link}>
						{linkText}
						<IconArrow
							direction={"right"}
							aria-labelledby='directional chevron pointing right'
						/>
					</Link>
				</div>
				<Swiper
					modules={[Navigation, Scrollbar]}
					slidesPerView={"auto"}
					spaceBetween={20}
					scrollbar={{ enabled: true, draggable: true }}
					navigation={{
						enabled: true,
						nextEl: `[data-button-ref="${carouselId}-button-next"]`,
						prevEl: `[data-button-ref="${carouselId}-button-prev"]`,
					}}
					className={styles.events}
				>
					{events &&
						events.map((event) => (
							<SwiperSlide key={jumbleString(`ebgdaebgtrf${event.link}`)}>
								<Link href={event.link}>
									<div className={styles.eventWrapper}>
										<img
											src={`${event.image}?format=webp`}
											alt={event.altText}
										/>
										<div className={styles.details}>
											<span className={styles.dates}>
												<PublishDate date={event.startDate} />
												{event.startDate && event.endDate && " - "}
												{event.startDate && (
													<PublishDate date={event.endDate} />
												)}
											</span>
											<h4>{event.title}</h4>
											<p className={styles.price}>{event.price}</p>
										</div>
									</div>
								</Link>
							</SwiperSlide>
						))}

					<SwiperNavigationButtons buttonIdentifier={carouselId} />
				</Swiper>
			</div>
		</section>
	);
};

export default EventsCarousel;

Builder.registerComponent(EventsCarousel, {
	name: "Events Carousel",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "string",
			defaultValue: "Title",
			required: true,
		},
		{
			name: "link",
			friendlyName: "Link",
			type: "string",
			defaultValue: "#",
			required: true,
		},
		{
			name: "linkText",
			friendlyName: "Link Text",
			type: "string",
			defaultValue: "View All Events",
			required: true,
		},
		{
			name: "events",
			friendlyName: "Events",
			type: "list",
			defaultValue: [
				{
					image: "https://placehold.co/350x350",
					altText: "this is alt text",
					helperText: "Recommended size: 350px x 350px",
					link: "#",
					startDate: "January, 01, 2024",
					endDate: "December, 01, 2024",
					title: "This is the title",
					price: "$100",
				},
			],
			subFields: [
				{
					name: "image",
					friendlyName: "Image",
					helperText: "Recommended size: 350px x 350px",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					defaultValue: "https://placehold.co/350x350",
					required: true,
				},
				{
					name: "altText",
					friendlyName: "Alt Text",
					type: "string",
					defaultValue: "alt text",
					required: true,
				},
				{
					name: "link",
					friendlyName: "Link",
					type: "string",
					defaultValue: "#",
					required: true,
				},
				{
					name: "startDate",
					friendlyName: "Start Date",
					type: "date",
					defaultValue: "January, 01, 2024",
					required: true,
				},
				{
					name: "endDate",
					friendlyName: "End Date",
					type: "date",
					defaultValue: "December 01, 2024",
				},
				{
					name: "title",
					friendlyName: "Course Title",
					type: "string",
					defaultValue: "Title",
					required: true,
				},
				{
					name: "price",
					friendlyName: "Price",
					type: "string",
					defaultValue: "$100",
				},
			],
		},
	],
});
