import { Builder } from "@builder.io/react";
import React, { FC, useState } from "react";
import { Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavigationButtons from "../SwiperNavigationButton/SwiperNavigationButton";
import styles from "./ImageSlider.module.scss";
import clsx from "clsx";

interface ImageSliderProps {
	prop?: any;
	images: [
		{
			imageSrc?: string;
			altText?: string;
		},
	];
	backgroundColor: string;
	topPadding: boolean;
}

const ImageSlider: FC<ImageSliderProps> = ({
	images,
	backgroundColor,
	topPadding,
}) => {
	const style = {
		"--custom-background-color": backgroundColor,
	} as React.CSSProperties;

	const [swiperRef, setSwiperRef] = useState<any>();

	const carouselId = "image-slider-carousel";

	return (
		<section
			className={clsx([
				styles.slideWrapper,
				topPadding ? styles.topPadding : "",
			])}
			style={style}
		>
			<div>
				<Swiper
					modules={[Scrollbar, Navigation]}
					scrollbar={true}
					navigation={{
						enabled: true,
						nextEl: `[data-button-ref="${carouselId}-button-next"]`,
						prevEl: `[data-button-ref="${carouselId}-button-prev"]`,
					}}
					slidesPerView={1}
					spaceBetween={24}
					breakpoints={{
						1200: {
							slidesPerView: 4,
						},
						1000: {
							slidesPerView: 3.5,
						},
						801: {
							slidesPerView: 2.5,
						},
						551: {
							slidesPerView: 2,
						},
					}}
					onSwiper={(swiper) => setSwiperRef(swiper)}
				>
					{images.map((image, idx) => (
						<SwiperSlide
							className={styles.slide}
							key={`${idx}-${image.imageSrc}`}
						>
							<img src={`${image.imageSrc}?format=webp`} alt={image.altText} />
						</SwiperSlide>
					))}
				</Swiper>
				<SwiperNavigationButtons
					swiperRef={swiperRef}
					buttonIdentifier={carouselId}
				/>
			</div>
		</section>
	);
};

export default ImageSlider;

Builder.registerComponent(ImageSlider, {
	name: "Image Slider",
	inputs: [
		{
			name: "backgroundColor",
			friendlyName: "Background Color",
			type: "color",
			defaultValue: "#0C436A",
		},
		{
			name: "topPadding",
			friendlyName: "Add Top Padding",
			type: "boolean",
			defaultValue: false,
		},
		{
			name: "images",
			friendlyName: "Images",
			type: "list",
			defaultValue: [
				{
					imageSrc: "https://placehold.co/310x175",
					altText: "This is alt text",
				},
			],
			subFields: [
				{
					name: "imageSrc",
					friendlyName: "Image",
					type: "file",
					allowedFileTypes: ["jpeg", "jpg", "png"],
					defaultValue: "https://placehold.co/310x175",
					helperText: "310×175 px | .jpeg, .jpg, .png | ~125 KB",
				},
				{
					name: "altText",
					friendlyName: "Alt Text",
					type: "string",
					required: true,
					defaultValue: "This is alt text",
				},
			],
		},
	],
});
